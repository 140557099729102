<template>
  <el-aside :class="['sidebar', getSideBarCollapse ? 'sidebar-close' : '']">
    <div class="sidebar-wrap">
      <div class="logo">
        <img :src="require(`@/assets/images/${getSideBarCollapse ? 'dayungs_logo2' : 'dayungs_logo'}.png`)" alt="" />
      </div>
      <el-menu
        :default-active="activePage"
        :collapse="getSideBarCollapse"
        :collapse-transition="false"
        unique-opened
        router
        style="border: none"
      >
        <div v-for="(item, index) in menuList" :key="index">
          <el-sub-menu v-if="item.submenu" :index="item.path">
            <template #title>
              <i class="bi icon" :class="`bi-${item.icon}`"></i>
              <span>{{ item.meta.name }}</span>
            </template>
            <el-menu-item
              v-for="(subItem, subIndex) in item.children"
              :key="subIndex"
              :index="`${item.path}/${subItem.path}`"
              :style="
                subItem.meta.headquarters !== undefined
                  ? subItem.meta.headquarters.toString() === is_headquarters
                    ? ''
                    : 'display:none'
                  : ''
              "
              @click="handleOrder(`${item.path}/${subItem.path}`)"
              class="sub-menu-item"
            >
              {{ subItem.meta.name }}
            </el-menu-item>
          </el-sub-menu>
          <el-menu-item v-else :index="item.path" class="single-menu-item" @click="handleOrder(item.path)">
            <template #title>
              <i class="bi icon" :class="`bi-${item.icon}`"></i>
              <span>{{ item.meta.name }}</span>
            </template>
          </el-menu-item>
        </div>
      </el-menu>
      <small v-if="!getSideBarCollapse" class="version">version : {{ getVersion }}</small>
    </div>
  </el-aside>
</template>
<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { routes, headquarters_submenu, shop_submenu } from '@/router/index';
import { useGetters } from '@/utils/mapStore';
export default {
  name: 'v-sidebar',
  setup() {
    const activePage = ref('');
    const route = useRoute();
    const router = useRouter();
    //取得版號和Collapse
    const { getSideBarCollapse, getVersion, getPermissionTree } = useGetters('user', [
      'getSideBarCollapse',
      'getVersion',
      'getPermissionTree',
    ]);
    //取得menu清單
    let menuList = [];
    //如需增加側邊欄位，前後端的name須同步新增，才可成功更新菜單
    getPermissionTree.value.forEach((item) => {
      routes.filter((e) => {
        if (item.name === e.name) {
          menuList.push(e);
        }
      });
    });
    //確認是總公司還門市
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    if (is_headquarters === 'true') {
      //總公司
      menuList = menuList.filter((e) => headquarters_submenu.includes(e.name));
    } else {
      //門市
      menuList = menuList.filter((e) => shop_submenu.includes(e.name));
    }
    //取得目前選單
    const getActivePage = () => {
      activePage.value = `/${route.path.split('/')[1]}/${route.path.split('/')[2]}`;
      //修正沒有submenu的選中狀態
      const unSubmenu = routes.filter((e) => e.submenu === false);
      unSubmenu.forEach((e) => {
        if (activePage.value.startsWith(e.path)) activePage.value = e.path;
      });
    };
    //監聽網址變動
    watch(
      route,
      () => {
        getActivePage();
      },
      { immediate: true }
    );
    //修正點擊
    const handleOrder = (path) => {
      if (route.fullPath.startsWith(path)) router.push(route.fullPath);
    };
    return {
      getSideBarCollapse,
      getVersion,
      is_headquarters,
      activePage,
      menuList,
      handleOrder,
    };
  },
};
</script>
<style lang="scss" scoped>
$margin: calc(var(--el-menu-base-level-padding) + var(--el-menu-level) * var(--el-menu-level-padding));
.sidebar {
  width: $sidebar-width;
  box-shadow: 6px 0 6px 0 rgba(232, 232, 232, 0.5);
  transition: 0.5s;
}
.sidebar-wrap {
  position: relative;
  background: #fff;
  width: $sidebar-width;
  height: 100vh;
  font-weight: 500;
  position: fixed;
  overflow: hidden;
  transition: 0.5s;
}
.sidebar-close {
  width: 64px;
  .sidebar-wrap {
    width: 64px;
  }
  .logo img {
    height: 60px;
  }
}
.logo {
  width: 100%;
  height: $header-height;
  @include flex-center-center;
  img {
    height: 50px;
  }
  margin-bottom: 60px;
}
.sub-menu-item {
  border-left: 1px solid $color-line;
  margin-left: $margin;
}
.single-menu-item {
  border-left: 6px solid transparent;
  color: $color-text-light !important;
}
.single-menu-item.is-active {
  background-color: $color-background;
  border-left: 6px solid $color-primary;
  color: $color-text !important;
}
.icon {
  margin-left: 6px;
  margin-right: calc($margin * 2 - 9px) !important;
  font-size: 1.8rem;
}
.logout {
  position: absolute;
  bottom: 35px;
  left: 26px;
  font-size: 1.5rem;
  cursor: pointer;
  @include flex-center-center;
  @include base-hover;
  span {
    margin-left: 30px;
    white-space: nowrap;
  }
}
.version {
  position: absolute;
  bottom: 15px;
  left: 30px;
  color: $color-line;
  margin-top: 5px;
  white-space: nowrap;
}
:deep(.el-sub-menu__title) {
  border-left: 6px solid transparent;
  color: $color-text-light !important;
}
:deep(.el-sub-menu.is-active .el-sub-menu__title) {
  background-color: $color-background;
  border-left: 6px solid $color-primary;
  color: $color-text !important;
}
.sidebar-close {
  :deep(.el-sub-menu .el-sub-menu__icon-arrow) {
    display: none;
  }
}
</style>
